<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>disability-wheelchair</title>
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="11.25" cy="3.56" r="2.625" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M17.408,22.231,15.572,18.56H11.25a1.5,1.5,0,0,1-1.5-1.5V9.56a1.5,1.5,0,0,1,3,0v6H16.5a1.5,1.5,0,0,1,1.342.829l2.25,4.5a1.5,1.5,0,0,1-2.684,1.342Z"
    />
    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12.75,22.257a6,6,0,0,1-6-10.394" />
  </svg>
</template>
